import React from "react";
import {
  ElementFactory,
  settings,
  QuestionTextModel,
  Serializer,
  FunctionFactory,
  Helpers,
  QuestionTextBase,
} from "survey-core";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  Survey,
  SurveyPanel,
  SurveyQuestionText,
  SurveyQuestionElementBase,
  CharacterCounterComponent,
} from "survey-react-ui";
import { getI18n } from "react-i18next";
// mui start
import { OutlinedInputProps, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import styled from "@emotion/styled";
// mui end

import * as style from "../survey/common.style";

import { LocalizationProvider, DatePicker as MUIDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { CustomType, EndAdornment, CustomFunctionName } from "../survey/survey.util";

import CustomDatePicker from "../../custom-form/custom-date-picker";
import { ColorButton, OtpButton } from "./button";
import { color } from "../../../../../mui-theme/palette";

const CUSTOM_TYPE = CustomType.Text;
dayjs.extend(utc);
dayjs.extend(timezone);
export class QuestionTextFieldModel extends QuestionTextModel {}

// Add question type metadata for further serialization into JSON
export const initTextSerializer = () => {
  Serializer.addClass(
    CUSTOM_TYPE,
    [
      {
        name: "placeholder",
        default: "Please input",
      },
      {
        name: "inputType",
        default: "text",
      },
      {
        name: "keyboardType",
        default: "text",
      },
      {
        name: "contractMonth",
        default: 0,
      },
      {
        name: "tilNow",
        default: false,
      },
      {
        name: "otpConfig",
        default: {},
      },
      {
        name: "isAutoCapitalize",
        default: false,
      },
      {
        name: "maxValueExpression",
        default: "",
      },
      {
        name: "tooltips",
        default: "",
      },

      {
        name: "inputType",
        default: "text",
        choices: settings.questions.inputTypes,
      },
      {
        name: "size:number",
        minValue: 0,
        dependsOn: "inputType",
        visibleIf: function (obj: any) {
          if (!obj) return false;
          return obj.isTextInput;
        },
      },
      {
        name: "textUpdateMode",
        default: "default",
        choices: ["default", "onBlur", "onTyping"],
        dependsOn: "inputType",
        visibleIf: function (obj: any) {
          if (!obj) return false;
          return obj.isTextInput;
        },
      },
      {
        name: "autocomplete",
        alternativeName: "autoComplete",
        choices: settings.questions.dataList,
      },
      {
        name: "min",
        dependsOn: "inputType",
        visibleIf: function (obj: any) {
          return isMinMaxType(obj);
        },
        onPropertyEditorUpdate: function (obj: any, propertyEditor: any) {
          propertyEditorMinMaxUpdate(obj, propertyEditor);
        },
        onSettingValue: (obj: any, val: any): any => {
          return getCorrectMinMax(obj, val, obj.max, false);
        },
      },
      {
        name: "max",
        dependsOn: "inputType",
        nextToProperty: "*min",
        visibleIf: function (obj: any) {
          return isMinMaxType(obj);
        },
        onSettingValue: (obj: any, val: any): any => {
          return getCorrectMinMax(obj, obj.min, val, true);
        },
        onPropertyEditorUpdate: function (obj: any, propertyEditor: any) {
          propertyEditorMinMaxUpdate(obj, propertyEditor);
        },
      },
      {
        name: "minValueExpression:expression",
        category: "logic",
        dependsOn: "inputType",
        visibleIf: function (obj: any) {
          return isMinMaxType(obj);
        },
      },
      {
        name: "maxValueExpression:expression",
        category: "logic",
        dependsOn: "inputType",
        visibleIf: function (obj: any) {
          return isMinMaxType(obj);
        },
      },
      {
        name: "minErrorText",
        serializationProperty: "locMinErrorText",
        dependsOn: "inputType",
        visibleIf: function (obj: any) {
          return isMinMaxType(obj);
        },
      },
      {
        name: "maxErrorText",
        serializationProperty: "locMaxErrorText",
        dependsOn: "inputType",
        visibleIf: function (obj: any) {
          return isMinMaxType(obj);
        },
      },
      {
        name: "inputTextAlignment",
        default: "auto",
        choices: ["left", "right", "auto"],
        visible: false,
      },
      {
        name: "maskType:masktype",
        default: "none",
        visibleIndex: 0,
        dependsOn: "inputType",
        visibleIf: (obj: any) => {
          return obj.inputType === "text";
        },
      },
      {
        name: "maskSettings:masksettings",
        className: "masksettings",
        visibleIndex: 1,
        dependsOn: "inputType",
        visibleIf: (obj: any) => {
          return obj.inputType === "text";
        },
        onGetValue: function (obj: any) {
          return obj.maskSettings.getData();
        },
        onSetValue: function (obj: any, value: any) {
          obj.maskSettings.setData(value);
        },
      },
      {
        name: "step:number",
        dependsOn: "inputType",
        visibleIf: function (obj: any) {
          if (!obj) return false;
          return obj.inputType === "number" || obj.inputType === "range";
        },
      },
      {
        name: "maxLength:number",
        default: -1,
        dependsOn: "inputType",
        visibleIf: function (obj: any) {
          if (!obj) return false;
          return obj.isTextInput;
        },
      },
      {
        name: "placeholder",
        alternativeName: "placeHolder",
        serializationProperty: "locPlaceholder",
        dependsOn: "inputType",
        visibleIf: function (obj: any) {
          if (!obj) return false;
          return obj.isTextInput;
        },
      },
      {
        name: "dataList:string[]",
        serializationProperty: "locDataList",
        dependsOn: "inputType",
        visibleIf: function (obj: any) {
          if (!obj) return false;
          return obj.inputType === "text";
        },
      },
    ],
    function () {
      return new QuestionTextFieldModel("");
    },
    "textbase",
  );
};
const theme = createTheme({
  palette: {
    primary: {
      main: color.majorRed,
      dark: color.majorRed,
      // contrastText: will be calculated to contrast with palette.primary.main
    } as any,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  } as any,
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
        today: {
          backgroundColor: color.redLight,
        },
      },
    },
  } as any,
});

ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
  return new QuestionTextFieldModel(name);
});
// A class that renders questions of the new type in the UI
export class SurveyQuestionTextField extends SurveyQuestionText {
  constructor(props: any) {
    super(props);
    this.state = {
      value: ["date", "month", "year"].includes(this.inputType)
        ? this.onDateChange(this.question.value)
        : this.question.value,
      otpValue: undefined,
    };
  }

  get readOnly() {
    let res = false;
    if (this.questionBase.survey) {
      const activePage: any = this.questionBase.survey.currentPage;
      if (activePage) {
        res = activePage.readOnly;
      }
    }
    return res || this.question.readOnly;
  }
  get value() {
    return this.question.value;
  }
  get inputType() {
    return this.question.inputType;
  }
  get keyboardType() {
    return this.question.keyboardType;
  }
  get contractMonth() {
    return this.question.contractMonth;
  }
  get tilNow() {
    return this.question.tilNow;
  }
  get otpConfig(): EndAdornment {
    return this.question.otpConfig;
  }
  get isAutoCapitalize() {
    return this.question.isAutoCapitalize;
  }

  get maxValueExpression() {
    return this.question.maxValueExpression;
  }

  get minDate() {
    if (this.question.min !== undefined) {
      return dayjs(this.question.min);
    }
    if (this.inputType === "month") {
      return this.contractMonth && dayjs().startOf("month");
    } else if (this.inputType === "year") {
      return dayjs("1900-01-01").startOf("year");
    } else return undefined;
  }

  get maxDate() {
    if (this.question.max !== undefined) {
      return dayjs(this.question.max);
    }
    if (this.maxValueExpression === "today") {
      return dayjs();
    }
    if (this.inputType === "month") {
      return this.contractMonth && dayjs().add(this.contractMonth - 1, "months");
    } else if (this.inputType === "year") {
      return dayjs().startOf("year");
    } else return undefined;
  }

  onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event?.target || {};
    this.question.value = value;
  }
  get placeholder() {
    return this.question.placeholder;
  }

  onDateChange = (date: Dayjs | null | "Now") => {
    if (!date) return;
    if (date !== "Now") {
      const localTimezone = dayjs.tz.guess();
      this.question.value = dayjs(date).tz(localTimezone).format();
    } else {
      this.question.value = date;
    }
  };

  onFocus = (e: any) => {
    const value = this.question.value;
    this.question.onFocus(e);
    this.question.value = this.isAutoCapitalize ? value.toLocaleUpperCase() : value;
  };

  onBlur = () => {
    const value = this.question.value;
    this.question.value = this.isAutoCapitalize ? value.toLocaleUpperCase() : value;
  };

  onHandleDateValue = (date: Dayjs | null | "Now") => {
    if (!date || date === "Now") {
      return date;
    } else {
      return dayjs(new Date(date as any));
    }
  };
  sendOtp = () => {
    FunctionFactory.Instance.run(CustomFunctionName.sendOtp, [
      this.question.value,
      this.question.survey,
      this.otpConfig,
    ]);
  };
  cusmterize = (inputProps: Partial<OutlinedInputProps>) => {
    return (
      <FormControl fullWidth sx={style.formControl} variant="standard">
        <TextField
          id={this.question.inputId}
          ref={(input) => {
            // this.setControl(input);
          }}
          InputProps={inputProps}
          //@ts-ignore
          onFocus={this.onFocus.bind(this)}
          onBlur={this.onBlur.bind(this)}
          autoComplete="off"
          disabled={this.readOnly}
          inputMode={inputTypeToInputMode(this.keyboardType ?? this.inputType)}
          type={this.keyboardType ?? this.inputType}
          value={this.question.value}
          placeholder={this.placeholder}
          onChange={this.onInputChange.bind(this)}
          sx={{
            ".MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: color.greyCc,
              color: color.greyCc,
              backgroundColor: color.greyBg,
            },
            ".MuiInputBase-root:before": {
              borderBottom: "1px solid #f0f0f0",
            },
          }}
          InputLabelProps={style.inputLabelProps}
          size="small"
          variant="standard"
        />
      </FormControl>
    );
  };

  getInputDefault = () => {
    let inputProps: Partial<OutlinedInputProps> = {
      inputProps: { inputMode: inputTypeToInputMode(this.keyboardType ?? this.inputType) },
    };
    if (this.otpConfig && !this.readOnly) {
      inputProps = {
        ...inputProps,
        endAdornment: (
          <InputAdornment position={this.otpConfig.position ?? "end"}>
            <OtpButton
              disabled={this.otpConfig.disabled}
              onClick={() => {
                this.sendOtp();
              }}
            ></OtpButton>
          </InputAdornment>
        ),
      };
      return this.cusmterize(inputProps);
    }
    const inputClass = (this.question as QuestionTextModel).getControlClass();

    const placeholder = this.question.renderedPlaceholder;
    if (this.question.isReadOnlyRenderDiv()) {
      return <div>{this.question.value}</div>;
    }
    const counter = !!this.question.getMaxLength() ? (
      <CharacterCounterComponent
        counter={this.question.characterCounter}
        remainingCharacterCounter={this.question.cssClasses.remainingCharacterCounter}
      ></CharacterCounterComponent>
    ) : null;

    return (
      <FormControl fullWidth sx={style.formControl} variant="standard">
        <TextField
          id={this.question.inputId}
          className={inputClass}
          InputProps={inputProps}
          autoComplete={this.question.autocomplete}
          placeholder={placeholder}
          onBlur={this.question.onBlur}
          onFocus={this.question.onFocus}
          onChange={this.question.onChange}
          onKeyUp={this.question.onKeyUp}
          onKeyDown={this.question.onKeyDown}
          onCompositionUpdate={(event) => this.question.onCompositionUpdate(event.nativeEvent)}
          disabled={this.readOnly}
          inputMode={inputTypeToInputMode(this.keyboardType ?? this.inputType)}
          inputRef={(input) => {
            this.setControl(input);
          }}
          type={this.keyboardType ?? this.inputType}
          sx={{
            ".MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: color.greyCc,
              color: color.greyCc,
              backgroundColor: color.greyBg,
            },
            ".MuiInputBase-root:before": {
              borderBottom: "1px solid #f0f0f0",
            },
          }}
          InputLabelProps={style.inputLabelProps}
          size="small"
          aria-required={this.question.a11y_input_ariaRequired}
          aria-label={this.question.a11y_input_ariaLabel}
          aria-labelledby={this.question.a11y_input_ariaLabelledBy}
          aria-describedby={this.question.a11y_input_ariaDescribedBy}
          aria-invalid={this.question.a11y_input_ariaInvalid}
          aria-errormessage={this.question.a11y_input_ariaErrormessage}
          variant="standard"
        />
        {counter}
      </FormControl>
    );
  };

  renderElement() {
    const datePickerLocaleText = {
      okButtonLabel: getI18n().t("global.text.confirm"),
      cancelButtonLabel: getI18n().t("app.button.cancel"),
      toolbarTitle: getI18n().t("Recruitment.select_date"),
    };
    switch (this.inputType) {
      case "date":
        return (
          <FormControl fullWidth variant="standard">
            <ThemeProvider theme={theme}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                // localeText={zhCN.components.MuiLocalizationProvider.defaultProps.localeText}
              >
                <DatePicker
                  localeText={datePickerLocaleText}
                  ref={(input) => this.setControl(input)}
                  views={viewsMap[this.inputType]}
                  readOnly={this.readOnly}
                  slotProps={{
                    textField: {
                      size: "small",
                      inputProps: {
                        onFocus: (e: any) => {
                          e.target.blur();
                        },
                        ...(this.placeholder ? { placeholder: this.placeholder } : {}),
                      },
                    },
                  }}
                  format={dateFormatMap[this.inputType]}
                  value={
                    this.question.value ? dayjs(new Date(this.question.value)) : this.question.value
                  }
                  onChange={this.onDateChange.bind(this) as any}
                  maxDate={this.maxDate}
                  minDate={this.minDate}
                />
              </LocalizationProvider>
            </ThemeProvider>
          </FormControl>
        );
      case "month":
      case "year":
        return (
          <FormControl fullWidth variant="standard">
            <ThemeProvider theme={theme}>
              <CustomDatePicker
                localeText={datePickerLocaleText}
                views={viewsMap[this.inputType]}
                onFocus={this.question.onFocus.bind(this)}
                openTo={this.inputType}
                readOnly={this.readOnly}
                slotProps={{ textField: { size: "small" } }}
                format={dateFormatMap[this.inputType]}
                value={this.onHandleDateValue(this.question.value)}
                onChange={this.onDateChange.bind(this)}
                maxDate={this.maxDate}
                minDate={this.minDate}
                isTilNow={this.question.tilNow}
                inputType={this.inputType}
              />
            </ThemeProvider>
          </FormControl>
        );
      default: {
        return this.getInputDefault();
      }
    }
  }
}

const dateViews = ["year", "month", "day"];
const monthViews = ["year", "month"];
const yearViews = ["year"];
const viewsMap: any = {
  date: dateViews,
  month: monthViews,
  year: yearViews,
};
const dateFormatMap: any = {
  date: "DD/MM/YYYY",
  month: "MM/YYYY",
  year: "YYYY",
};
/**
 * check email regex
 * @param param
 * @returns
 */
function emailValidate([value]: string[]): boolean {
  if (!value) {
    return true;
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(value);
}
/** custom validation */
FunctionFactory.Instance.register("emailValidate", emailValidate);

type inputMode =
  | "none"
  | "text"
  | "tel"
  | "url"
  | "email"
  | "numeric"
  | "decimal"
  | "search"
  | undefined;

function inputTypeToInputMode(type: string): inputMode {
  if (typeList.includes(type)) return type as inputMode;
  switch (type) {
    case "number":
      return "numeric";
    default:
      return "text";
  }
}
const typeList = ["none", "text", "tel", "url", "email", "numeric", "decimal", "search", undefined];
const minMaxTypes = ["number", "range", "date", "datetime-local", "month", "time", "week"];

export function isMinMaxType(obj: any): boolean {
  const t = !!obj ? obj.inputType : "";
  if (!t) return false;
  return minMaxTypes.indexOf(t) > -1;
}
function getWeekTimeNumber(str: string, delimiter: string): number {
  const strs = str.split(delimiter);
  if (strs.length !== 2) return -1;
  if (!Helpers.isNumber(strs[0]) || !Helpers.isNumber(strs[1])) return -1;
  return parseFloat(strs[0]) * 60 + parseFloat(strs[1]);
}
function isMinBiggerWeekTime(minStr: string, maxStr: string, delimiter: string): boolean {
  const min = getWeekTimeNumber(minStr, delimiter);
  const max = getWeekTimeNumber(maxStr, delimiter);
  if (min < 0 || max < 0) return false;
  return min > max;
}
function getCorrectMinMax(obj: QuestionTextBase, min: any, max: any, isMax: boolean): any {
  let val = isMax ? max : min;
  if (!isMinMaxType(obj)) return val;
  if (Helpers.isValueEmpty(min) || Helpers.isValueEmpty(max)) return val;
  if (obj.inputType.indexOf("date") === 0 || obj.inputType === "month") {
    const isMonth = obj.inputType === "month";
    const dMin = new Date(isMonth ? min + "-1" : min);
    const dMax = new Date(isMonth ? max + "-1" : max);
    if (!dMin || !dMax) return val;
    if (dMin > dMax) return isMax ? min : max;
  }
  if (obj.inputType === "week" || obj.inputType === "time") {
    const delimiter = obj.inputType === "week" ? "-W" : ":";
    if (isMinBiggerWeekTime(min, max, delimiter)) return isMax ? min : max;
    return val;
  }
  if (obj.inputType === "number") {
    if (!Helpers.isNumber(min) || !Helpers.isNumber(max)) return val;
    if (Helpers.getNumber(min) > Helpers.getNumber(max)) return isMax ? min : max;
  }
  if (typeof min === "string" || typeof max === "string") return val;
  if (min > max) return isMax ? min : max;
  return val;
}

function propertyEditorMinMaxUpdate(obj: QuestionTextBase, propertyEditor: any): void {
  if (!!obj && !!obj.inputType) {
    propertyEditor.inputType = obj.inputType !== "range" ? obj.inputType : "number";
    propertyEditor.textUpdateMode = "onBlur";
  }
}

const DatePicker = styled(MUIDatePicker)(({ theme }) => ({
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "inherit",
    borderColor: "transparent",
    borderBottomColor: color.cobalt,
    borderWidth: "2px",
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    border: "inherit",
    borderColor: "transparent",
    borderBottomColor: "inherit",
    borderRadius: 0,
    borderWidth: "1px",
  },
  "& .MuiOutlinedInput-root>input.MuiInputBase-input": {
    paddingLeft: 0,
  },
}));
