export const booleanStyles = {
  booleanItemLeft: {
    flex: 1,
    borderRadius: "12px",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    marginRight: 0,
    marginLeft: 0,
  },
  booleanItemRight: {
    flex: 1,
    borderRadius: "12px",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    marginLeft: 0,
    marginRight: 0,
  },
  defaultButton: {
    borderRadius: "12px",
    border: "1px solid  #e5e7eb",
    color: "#333",
  },
  selectedBg: {
    background: "#FFE4E6",
  },
  defaultBg: {
    background: "#FFF",
  },
  selectedButton: {
    borderRadius: "12px",
    background: "#FFE4E6",
    color: "#E8192C",
    borderWidth: 0,
  },
};
