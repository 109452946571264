import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { get } from "lodash";
import { useNavigate } from "react-router-dom";
import { PruToast } from "../../../../components/pru-toast";
import { Region } from "../../../../components/region-phone/region-phone";
import { ONBOARDING_PATH, ROOT_PATH } from "../../../../routes/constants";
import { getGlobalConfigAsync, loginAsync, reset } from "../../../auth/redux/auth-slice";
import {
  selectCandidateProfile,
  selectError,
  selectIsLoading,
  selectToken,
} from "../../../auth/redux/selector";
import { initSurvey } from "../../components/surveyjs/survey/init-survey";
import { fetchGlobalConfig, fetchRecruitmentPublicConfig } from "../../network/network";
import { RoleEnum } from "../../types";
import { fakeToken, getToken, parseJwt } from "../../utils";

interface HookProps {}

export const useLogin = () => {
  initSurvey();
  const { t, i18n } = useTranslation();
  const userType = "CANDIDATE";

  // redux
  const dispatch = useDispatch();

  const [selectedLang, setSelectedLang] = useState(i18n.language);

  const token = useSelector(selectToken);
  const error = useSelector(selectError);
  const isLoading = useSelector(selectIsLoading);

  const navigate = useNavigate();
  const [region, setRegion] = useState<Region>({
    code: "+852",
    displayName: "Hong Kong",
  } as Region);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [islanguagePopupOpen, setIslanguagePopupOpen] = useState(false);
  const [isCandidateWebLoginEnabled, setIsCandidateWebLoginEnabled] = useState(true);

  const handleLogin = async () => {
    const username = `${region.code} ${phoneNumber}`;
    if (phoneNumber.length === 0 || password.length === 0) {
      alert(t("Common.login_id_or_password_cannot_empty"));
      return;
    }

    await dispatch<any>(loginAsync({ username, password, userType }));
    const token = getToken();
    const tokenData = token ? parseJwt(token) : {};

    // Check if there are stored routes and state."
    const lastPath = sessionStorage.getItem("LAST_PATH");
    const navigateState = JSON.parse(sessionStorage.getItem("LAST_NAVIGATE_STATE") || "{}");
    const lastAuthToken = sessionStorage.getItem("LAST_AUTH_TOKEN");

    if (tokenData && lastAuthToken) {
      const currentCandidateId = tokenData?.sub;
      const lasttCandidateId = parseJwt(lastAuthToken)?.sub;

      if (lastPath && currentCandidateId === lasttCandidateId) {
        navigate(lastPath, { state: navigateState });
        clearSessionStorage();
        return;
      }
    }

    if (tokenData?.role === RoleEnum.CANDIDATE) {
      navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/candidate-home`);
    } else if (tokenData?.role === RoleEnum.PENDING_CANDIDATE) {
      navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/join-now`, { state: { userName: username } });
    }
    clearSessionStorage();
  };
  const clearSessionStorage = () => {
    sessionStorage.removeItem("LAST_PATH");
    sessionStorage.removeItem("LAST_NAVIGATE_STATE");
    sessionStorage.removeItem("LAST_AUTH_TOKEN");
  };

  const handleCreateAccount = () => {
    navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/otp-verification`);
  };

  const handleForgetPassword = () => {
    navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/otp-verification`, {
      state: { isForgetPassword: true },
    });
  };

  const handleDisclaimerClick = () => {
    navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/protocol`, {
      state: { title: t("Common.disclaimer"), des: t("Settings.disclaimer_content") },
    });
  };
  const handlePrivacyPolicyClick = () => {
    navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/protocol`, {
      state: { title: t("Common.privacy_notice"), des: t("Settings.privacy_policy_content") },
    });
  };

  const toggleLanguagePopupOpen = () => {
    setIslanguagePopupOpen(!islanguagePopupOpen);
  };

  const handleLanguageChange = (lng: any) => {
    setIslanguagePopupOpen(false);
    setSelectedLang(lng);
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const fetchConfigs = async () => {
      try {
        const [globalConfig, recruitmentConfig] = await Promise.all([
          fetchGlobalConfig(),
          fetchRecruitmentPublicConfig(),
        ]);
        const enableCandidateLogin = get(
          globalConfig,
          "data.content.onboardingConfig.enableCandidateLogin",
          false,
        );
        const candidateLoginChannel = get(
          recruitmentConfig,
          "data.content.Recruitment.candidateLoginChannel",
          [],
        );
        const flag = enableCandidateLogin && candidateLoginChannel.includes("WEB");
        setIsCandidateWebLoginEnabled(flag);
      } catch (error) {
        console.error("Error fetching configs:", error);
      }
    };

    fetchConfigs();
  }, [dispatch]);

  useEffect(() => {
    dispatch<any>(getGlobalConfigAsync());
    sessionStorage.removeItem("AUTH_TOKEN");
    dispatch(reset());
  }, [dispatch]);

  useEffect(() => {
    if (token && getToken() !== fakeToken) {
      if (token?.role === RoleEnum.CANDIDATE) {
        navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/candidate-home`);
      } else if (token?.role === RoleEnum.PENDING_CANDIDATE) {
        const userName =
          region.code && phoneNumber
            ? `${region.code} ${phoneNumber}`
            : sessionStorage.getItem("USER_NAME") ?? "";

        if (userName) {
          navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/join-now`, {
            state: { userName },
          });
        }
      }
    }

    if (error) {
      PruToast({
        message: error,
        status: "error",
        duration: 3000,
      });
      dispatch(reset());
    }
  }, [token, error, navigate, dispatch]);

  return {
    t,
    isCandidateWebLoginEnabled,
    selectedLang,
    region,
    setRegion,
    phoneNumber,
    handleLogin,
    setPhoneNumber,
    password,
    setPassword,
    isLoading,
    islanguagePopupOpen,
    toggleLanguagePopupOpen,
    handleLanguageChange,
    handleCreateAccount,
    handleForgetPassword,
    handleDisclaimerClick,
    handlePrivacyPolicyClick,
  };
};
