import React from "react";
import { useGlobalModal } from "../../use-modal/use-modal";
import { Modal } from "../../use-modal/modal.type";
import { useTranslation } from "react-i18next";
import closeLogo from "../../../components/icon/assets/close.svg";
import styles from "./checkbox.module.css";

export type ModalData = { value?: string; label?: string; key?: string };

export interface CheckboxProps {
  title?: string;
  data: ModalData[];
  close?: () => void;
  selectData?: ModalData;
  onChange?: (d: ModalData) => void;
}

export const Checkbox = ({ title, data, close, selectData, onChange }: CheckboxProps) => {
  const { t } = useTranslation();

  const handSelect = (data: ModalData) => {
    onChange?.(data);
    close?.();
  };

  return (
    <div className="flex flex-col px-6 pt-5 pb-4 max-h-[512px] ">
      <div className="flex  justify-between">
        {title ? <span className="text-2xl font-semibold break-words">{title}</span> : null}
        <img src={closeLogo} alt="" onClick={close}></img>
      </div>
      <div className={styles.content}>
        {data.map((item) => (
          <button
            key={item.value}
            className={selectData?.value === item.value ? styles.selectBtn : styles.unSelectBtn}
            onClick={() => {
              handSelect(item);
            }}
          >
            {item.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export const checkboxPopup = (props: CheckboxProps, openProps?: Modal) => {
  return new Promise<{ ok: boolean }>((resolve, reject) => {
    useGlobalModal().open(<Checkbox {...props} />, {
      showHeader: false,
      ...openProps,
    });
  });
};
