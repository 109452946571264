import React, { useState, useCallback } from "react";
import { throttle } from "lodash";
import "./search-input.module.css";
import styles from "./search-input.module.css";
import { t } from "i18next";
import SearchIcon from "@mui/icons-material/Search";

export interface SearchInputProps {
  placeholderTip?: string;
  style?: React.CSSProperties;
  onChange?: (searchKey: string) => void;
  needThrottle: boolean;
  waitingTime: number;
  autoFocus?: boolean;
}

/**
 * Search Input component for web
 */
export const SearchInput: React.FC<SearchInputProps> = ({
  style,
  needThrottle = false,
  waitingTime = 1000,
  placeholderTip,
  onChange,
}) => {
  const [searchKey, setSearchKey] = useState("");
  const placeholder = placeholderTip || t("Common.search_by_keyword_or_code");

  const throttledInput = useCallback(
    throttle((searchKey) => onChange?.(searchKey), waitingTime, { leading: true, trailing: true }),
    [onChange],
  );

  const onSearchKeyChange = (value: string) => {
    setSearchKey(value);
    if (onChange) {
      if (needThrottle) {
        throttledInput(value);
      } else {
        onChange(value);
      }
    }
  };

  return (
    <div className={styles.searchContainer} style={style}>
      <SearchIcon className="text-base" style={{ color: "#999" }} />
      <input
        type="text"
        value={searchKey}
        onChange={(e) => onSearchKeyChange(e.target.value)}
        placeholder={placeholder}
        className={styles.searchInput}
        autoFocus={false}
      />
      {searchKey && (
        <button
          className={styles.clearButton}
          onClick={() => {
            setSearchKey("");
            if (onChange) {
              onChange("");
            }
          }}
        >
          ✖️
        </button>
      )}
    </div>
  );
};
