import { useCallback } from "react";

import { useNavigate } from "react-router-dom";
import { useCanGoBack } from "../../../../utils/hooks/use-can-go-back";

interface HookProps {
  id: number;
  // other params
}

export const useProtocolScreen = ({ id }: HookProps) => {
  const navigate = useNavigate();
  const canGoBack = useCanGoBack();

  const handleClose = useCallback(() => {
    if (canGoBack()) {
      navigate(-1);
    }
  }, [canGoBack, navigate]);

  return {
    handleClose,
  };
};
