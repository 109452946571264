import React from "react";

import { useLocation } from "react-router-dom";
import { useProtocolScreen } from "./protocol-screen.hook";
import { ProtocolComponent } from "./components/protocol-component";
export const ProtocolScreenPage = () => {
  const { handleClose } = useProtocolScreen({ id: 1 });
  const location = useLocation();
  const { title, des } = location.state || {};

  return <ProtocolComponent title={title} desc={des} onClose={handleClose} />;
};
