import { get } from "lodash";
import axios from "axios";
import { fakeToken, getToken } from "../../onboarding/utils";

const APIM_BASE_URL = get(window, "envConfig.REACT_APP_APIM_BASE_URL", "");
const APIM_PUBLIC = get(window, "envConfig.REACT_APP_APIM_PUBLIC", "");
const APIM_CORE = get(window, "envConfig.REACT_APP_APIM_CORE", "");

export const CORE_URL = `${APIM_BASE_URL}${APIM_CORE}`;
export const getFullRegionList = async (): Promise<any> => {
  const response = await axios.get(`${APIM_BASE_URL}${APIM_PUBLIC}/configs/global-region`, {
    headers: {},
    params: {
      namespace: "defalut",
      group: "pruforce.Global",
    },
  });
  return get(response, "data.content.fullRegionList");
};

export const getRecruitPublicConfig = async (): Promise<any> => {
  const response = await axios.get(`${APIM_BASE_URL}${APIM_PUBLIC}/configs/recruitment-public`, {
    headers: {},
    params: {
      namespace: "defalut",
      group: "pruforce.Recruitment",
    },
  });
  return get(response, "data.content");
};

export const fetchPolicyConfig = async (): Promise<any> => {
  const configPaths = [
    `${CORE_URL}/configs/security-authorization-public-web?group=pruforce.Security&namespace=default`,
  ];

  try {
    const token = getToken();
    if (!token || token === fakeToken) {
      return [];
    }
    const responses = await Promise.allSettled(
      configPaths.map((path) =>
        axios.get(path, {
          headers: {
            "Ocp-Apim-Subscription-Key": get(
              window,
              "envConfig.REACT_APP_OEM_APIM_SUBSCRIPTION_KEY",
              "",
            ),

            Authorization: token,
          },
        }),
      ),
    );
    const successfulResponses = responses
      .filter((response) => response.status === "fulfilled")
      .map((response) => (response as PromiseFulfilledResult<any>).value);
    return successfulResponses;
  } catch (error) {
    return [];
  }
};
