import React, { useEffect, useState, useCallback } from "react";
import { getFullRegionList } from "../../modules/common/network/network";
import styles from "./region-phone.module.css";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { SvgAssets } from "../icon/assets";
import closeLogo from "../icon/assets/close.svg";
import Icon from "../icon/icon";
import { SearchInput } from "../search-input/search-input";

interface RegionPhoneProps {
  label?: string;
  region: Region;
  setRegion: (code: Region) => void;
  phoneNumber: string;
  setPhoneNumber: (number: string) => void;
}

export interface Region {
  code: string;
  displayName?: string;
}

export const RegionPhone: React.FC<RegionPhoneProps> = ({
  label,
  region,
  setRegion,
  phoneNumber,
  setPhoneNumber,
}) => {
  const { t } = useTranslation();

  const [regions, setRegions] = useState<Region[]>([]);
  const [filterRegions, setFilterRegions] = useState<Region[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    getFullRegionList()
      .then((regionConfigs: any) => {
        if (regionConfigs) {
          const regions = Object.values(regionConfigs).map((region: any) => ({
            code: region.code,
            displayName: region.displayName,
          }));
          setRegions(regions);
          setFilterRegions(_.cloneDeep(regions));
        } else {
          setRegions([{ code: "+852", displayName: "" }]);
          setFilterRegions([{ code: "+852", displayName: "" }]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const openPopup = () => {
    setInputValue("");
    setIsOpen(!isOpen);
  };

  const selectItem = (region: Region) => {
    setRegion(region);
    setIsOpen(false);
  };

  const onSearch = useCallback((value: string) => {
    setInputValue(value);
  }, []);

  useEffect(() => {
    const lowercasedStr = inputValue.toLowerCase();
    const result = regions.filter((region) => {
      return (
        region.code.toLowerCase().includes(lowercasedStr) ||
        region.displayName?.toLowerCase().includes(lowercasedStr)
      );
    });
    setFilterRegions(result);
  }, [inputValue, regions]);

  const highlightText = (text: string, searchStr: string) => {
    if (!searchStr) return text;
    const regex = new RegExp(`(${searchStr})`, "gi");
    const parts = text.split(regex);
    return parts.map((part, index) =>
      part.toLowerCase() === searchStr.toLowerCase() ? (
        <span key={index} style={{ color: "red" }}>
          {part}
        </span>
      ) : (
        part
      ),
    );
  };

  return (
    <div>
      <div className="mb-4">
        {label && (
          <label
            htmlFor="phone-number"
            className="block font-semibold text-gray-800 font-body text-sm leading-5 mb-1"
          >
            {label}
          </label>
        )}

        <div className="flex w-full border-b border-gray-300 focus:outline-none pb-2">
          <div className="flex" onClick={openPopup}>
            <span>{region.code}</span>
            <Icon svg={SvgAssets.arrow} width={24} height={24} />
          </div>

          <input
            type="tel"
            id="phone-number"
            className="w-full border-none focus:outline-none pl-2"
            placeholder=""
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div>
      </div>
      {isOpen && (
        <div className={styles.popupContain} onClick={openPopup}>
          <div
            className={styles.popupOverlay}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className={styles.header}>
              <img src={closeLogo} alt="" className={styles.logoImg} onClick={openPopup} />
              <span className={styles.title}>{t("Common.international_country_codes")}</span>
            </div>
            <SearchInput needThrottle={false} waitingTime={0} onChange={onSearch} />
            <div className={styles.countryListContainer}>
              <ul className={styles.countryList}>
                {filterRegions.map((r, index) => (
                  <li
                    key={index}
                    className={_.isEqual(region, r) ? styles.selectItem : styles.item}
                    onClick={() => selectItem(r)}
                  >
                    {highlightText(`${r.code} ${r.displayName}`, inputValue)}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
