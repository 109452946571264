import { useNavigate } from "react-router-dom";
import { ONBOARDING_PATH, ROOT_PATH } from "../../../../routes/constants";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCandidateProfileAsync, reset } from "../../../auth/redux/auth-slice";
import { selectCandidateProfile, selectToken } from "../../../auth/redux/selector";
import { TokenPayload } from "../../../auth/network/type";
import { getToken, languageSelectionConfig, parseJwt } from "../../utils";
import { merge } from "lodash";
import { confirm } from "../../../../components/modal/comfirm";
import { tipsPopup } from "../../../../components/modal/tips";
import { checkboxPopup } from "../../../../components/modal/checkbox/checkbox.component";
import { CandidateStatus } from "../../types";
import { color } from "../../../../mui-theme/palette";

interface HookProps {}

export const useCandidateHome = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
  const [isSettingPopupOpen, setIsSettingPopupOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState(i18n.language);

  const tokenData = useMemo(() => {
    const tokenFromStorage = getToken();
    const tokenPayload = parseJwt(tokenFromStorage);
    return merge(tokenPayload, token);
  }, [token]);

  const candidateProfile = useSelector(selectCandidateProfile);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        if (tokenData) {
          const res: TokenPayload = tokenData;
          const candidateId = res?.sub;
          if (candidateId) {
            await dispatch<any>(getCandidateProfileAsync(candidateId));
          }
        }
      } catch (error) {
        console.error("Failed to fetch candidate profile:", error);
      }
    };

    fetchProfile();
  }, [dispatch, tokenData]);
  useEffect(() => {
    const handleResize = () => {
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const clickEmail = () => {};
  const handleLanguageClick = () => {
    checkboxPopup({
      title: t("Common.language"),
      selectData: { value: selectedLang },
      data: languageSelectionConfig().availableLanguages.map((lang) => ({
        label: lang.languageFullName,
        value: lang.i18nLanguageCode,
      })),
      onChange: (data) => {
        if (data.value) {
          setSelectedLang(data.value);
        }
        i18n.changeLanguage(data?.value);
      },
    });
    setIsSettingPopupOpen(false);
  };

  const handleDisclaimerClick = () => {
    tipsPopup({ title: t("Common.disclaimer"), message: t("Settings.disclaimer_content") });
    setIsSettingPopupOpen(false);
  };
  const handlePrivacyPolicyClick = () => {
    tipsPopup({
      title: t("Common.privacy_notice"),
      message: t("Settings.privacy_policy_content"),
    });
    setIsSettingPopupOpen(false);
  };

  const clickLogout = () => {
    confirm({
      title: t("Common.are_you_sure_you_want_to_log_out"),
      message: "",
      confirmButtonText: t("global.text.confirm"),
      cancelButtonText: t("Cancel"),
    }).then((result) => {
      if (result.ok) {
        sessionStorage.removeItem("AUTH_TOKEN");
        dispatch(reset());
        navigate(`${ROOT_PATH}/login`);
      }
    });
  };

  const clickSetting = () => {
    if (isPortrait) {
      navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/setting`);
    } else {
      setIsSettingPopupOpen(!isSettingPopupOpen);
    }
  };
  const clickPopup = () => {
    setIsSettingPopupOpen(!isSettingPopupOpen);
  };
  const clickProfile = useCallback(() => {
    navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/join-now`, {
      state: {
        candidateProfile,
      },
    });
  }, [navigate, candidateProfile]);

  const labelOption = (
    status?: CandidateStatus,
  ): { backgroundColor: string; color: string; text: string } => {
    const _labelOption: { backgroundColor: string; color: string; text: string } = {
      backgroundColor: "",
      color: "",
      text: "",
    };

    switch (status) {
      case CandidateStatus.REJECTED:
        _labelOption.backgroundColor = color.orangeLight;
        _labelOption.color = color.orange;
        _labelOption.text = t("Common.rejected");
        break;
      case CandidateStatus.ACCEPTED:
        _labelOption.backgroundColor = color.greenLight;
        _labelOption.color = color.green;
        _labelOption.text = t("Common.accepted");
        break;
      case CandidateStatus.EXPIRED:
        _labelOption.backgroundColor = color.redLight;
        _labelOption.color = color.majorRed;
        _labelOption.text = t("Recruitment.expired");
        break;
      case CandidateStatus.CANCELLED:
        _labelOption.backgroundColor = color.greyBg;
        _labelOption.color = color.grey33;
        _labelOption.text = t("Recruitment.candidate_cancelled");
        break;
      default:
        _labelOption.backgroundColor = color.blueLight;
        _labelOption.color = color.blue;
        _labelOption.text = t("Recruitment.in_progress");
    }
    return _labelOption;
  };

  return {
    t,
    clickEmail,
    clickSetting,
    clickProfile,
    clickLogout,
    handleDisclaimerClick,
    handlePrivacyPolicyClick,
    handleLanguageClick,
    clickPopup,
    isPortrait,
    candidateProfile,
    isSettingPopupOpen,
    labelOption,
  };
};
