import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { SvgAssets } from "../../../components/icon/assets";
import { prompt } from "../../../components/modal/prompt";
import { ROOT_PATH } from "../../../routes/constants";
import { reset } from "../../auth/redux/auth-slice";
import { selectAutoLogoutMinutes } from "../../auth/redux/selector";
import { getToken } from "../../onboarding/utils";
import IdleTimer from "./idle-timer";

interface IdleTimerLayoutProps {
  children: React.ReactNode;
}

const IdleTimerLayout: React.FC<IdleTimerLayoutProps> = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const autoLogoutMinutes = useSelector(selectAutoLogoutMinutes) || 15;

  const showPrompt = () => {
    const config = {
      icon: SvgAssets.notice,
      title: t("reminder_title"),
      message: t("Common.system_idle_minutes", { number: autoLogoutMinutes }),
      confirmButtonText: t("Common.confirm"),
      onConfirm: () => {
        navigate(`${ROOT_PATH}/login`);
      },
    };
    prompt(config);
  };

  const handleIdle = () => {
    if (sessionStorage.getItem("AUTH_TOKEN")) {
      const currentPath = window.location.pathname;
      const currentState = location.state;
      sessionStorage.setItem("LAST_PATH", currentPath);
      sessionStorage.setItem("LAST_NAVIGATE_STATE", JSON.stringify(currentState));
      sessionStorage.setItem("LAST_AUTH_TOKEN", getToken());

      sessionStorage.removeItem("AUTH_TOKEN");
      dispatch(reset());
      showPrompt();
    }
  };

  useEffect(() => {
    const handleClick = () => {
      if (!sessionStorage.getItem("AUTH_TOKEN")) {
        showPrompt();
      }
    };

    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <>
      <IdleTimer onIdle={handleIdle} timeout={autoLogoutMinutes * 60 * 1000} />
      {children}
    </>
  );
};

export default IdleTimerLayout;
