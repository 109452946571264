export const OtpVerificationTemplate = {
  pages: [
    {
      name: "page1",
      elements: [
        {
          type: "paneldynamic",
          name: "regionPhone",
          title: "Common.registered_phone_number",
          titleLocation: "top",
          isRequired: true,
          templateElements: [
            {
              type: "dropdown",
              name: "regionCode",
              width: "25%",
              minWidth: "25%",
              maxWidth: "25%",
              startWithNewLine: false,
              allowClear: false,
              title: "Country Code",
              titleLocation: "hidden",
              isRequired: true,
              requiredErrorText: "Please select option where applicable",
              fieldType: "Standard",
              choices: ["+852", "+853", "+62"],
              modalConfig: {
                modalTitle: "Common.international_country_codes",
                showSearch: true,
              },
            },
            {
              type: "text",
              name: "phoneNumber",
              width: "75%",
              minWidth: "75%",
              maxWidth: "75%",
              startWithNewLine: false,
              titleLocation: "hidden",
              isRequired: true,
              textUpdateMode: "onTyping",
              placeholder: "Common.please_enter",
              requiredErrorText: "Common.please_enter",
              validators: [
                {
                  type: "expression",
                  text: "Common.please_input_real_phone",
                  expression:
                    "regionPhoneValidate({#regionPhone[0].regionCode},{regionPhone[0].phoneNumber})",
                },
              ],
              inputType: "tel",
            },
          ],
          allowAddPanel: false,
          allowRemovePanel: false,
          panelCount: 1,
          minPanelCount: 1,
          maxPanelCount: 1,
        },
        {
          type: "text",
          name: "otp",
          title: "Common.verify_code",
          isRequired: true,
          placeholder: "Common.please_enter",
          requiredErrorText: "Common.please_enter",
          textUpdateMode: "onTyping",
          visibleIf: "{regionPhone[0].phoneNumber.length} > 0",
          otpConfig: {
            position: "end",
            disabled: false,
            actionParams: {
              context: "FirstLogin",
              lang: "en",
              userType: "CANDIDATE",
              deliveryMethod: "SMS",
            },
          },
        },
        {
          type: "text",
          name: "otp",
          title: "Common.verify_code",
          isRequired: true,
          placeholder: "Common.please_enter",
          requiredErrorText: "Common.please_enter",
          textUpdateMode: "onTyping",
          visibleIf: "{regionPhone[0].phoneNumber.length} = 0",
          otpConfig: {
            position: "end",
            disabled: true,
            actionParams: {
              context: "FirstLogin",
              lang: "en",
              userType: "CANDIDATE",
              deliveryMethod: "SMS",
            },
          },
        },
      ],
    },
  ],
  showQuestionNumbers: "off",
};
