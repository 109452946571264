import React, { useEffect, useRef } from "react";

interface IdleTimerProps {
  onIdle: () => void;
  timeout?: number;
}

const IdleTimer: React.FC<IdleTimerProps> = ({ onIdle, timeout }) => {
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(onIdle, timeout);
    sessionStorage.setItem("LAST_ACTIVITY_TIME", Date.now().toString());
  };

  useEffect(() => {
    const lastActivityTime = Number(sessionStorage.getItem("LAST_ACTIVITY_TIME"));

    const currentTime = Date.now();
    if (lastActivityTime && currentTime - lastActivityTime > (timeout || 15 * 60 * 1000)) {
      onIdle();
    }
    resetTimer();

    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("click", resetTimer);
    window.addEventListener("scroll", resetTimer);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      window.removeEventListener("click", resetTimer);
      window.removeEventListener("scroll", resetTimer);
    };
  }, [onIdle, timeout]);

  return null;
};

export default IdleTimer;
