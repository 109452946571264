import React from "react";
import { useGlobalModal } from "../../use-modal/use-modal";
import { Modal } from "../../use-modal/modal.type";
import { useTranslation } from "react-i18next";
import closeLogo from "../../../components/icon/assets/close.svg";

export interface TipsModalProps {
  title?: string;
  message?: string;
  close?: () => void;
}

export const TipsModal = ({
  title,
  message,

  close,
}: TipsModalProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col px-6 pt-5 pb-4 h-[512px] ">
      <div className="flex  justify-between">
        {title ? <span className="text-2xl font-semibold break-words">{title}</span> : null}
        <img src={closeLogo} alt="" onClick={close}></img>
      </div>
      <div className="mt-3 mb-3 text-sm break-words">
        <span
          style={{
            whiteSpace: "pre-line",
          }}
        >
          {message}
        </span>
        <div className="mb-3" />
      </div>
    </div>
  );
};

export const tipsPopup = (props: TipsModalProps, openProps?: Modal) => {
  return new Promise<{ ok: boolean }>((resolve, reject) => {
    useGlobalModal().open(<TipsModal {...props} />, {
      showHeader: false,
      ...openProps,
    });
  });
};
