import { QuestionRadiogroupModel as Question } from "survey-core";
import { StringLiteral } from "typescript";

export enum OnboardingFormModuleName {
  PreQuestions = "preQuestions",
  PersonalData = "personalData",
  agencyData = "agencyData",
  careerNEmployment = "careerNEmployment",
  DocsUpload = "documentUploadModule",
  TermsNConditions = "termsNConditionsModule",
  ESignature = "eSignatureModule",
}

export enum OnboardingFlow {
  Recruiter = "RECRUITER",
  Candidate = "CANDIDATE",
}

export enum OnboardingApplicationStatus {
  DRAFT = "DRAFT",
  EDITING = "EDITING",
  PENDING_SIGNATURE = "PENDING_SIGNATURE",
  SUBMITTED = "SUBMITTED",
  FOLLOW_UP_REQUIRED = "FOLLOWUP_REQUIRED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  EXPIRED = "EXPIRED",
  DELETED = "DELETED",
  L1_PENDING_APPROVAL = "L1_PENDING_APPROVAL",
  L2_PENDING_APPROVAL = "L2_PENDING_APPROVAL",
  L3_PENDING_APPROVAL = "L3_PENDING_APPROVAL",
  L4_PENDING_APPROVAL = "L4_PENDING_APPROVAL",
  L1_APPROVED = "L1_APPROVED",
  L2_APPROVED = "L2_APPROVED",
  L3_APPROVED = "L3_APPROVED",
  CANCELLED = "CANCELLED",
}

export enum CandidateStatus {
  DRAFT = "DRAFT",
  IN_PROGRESS = "IN_PROGRESS",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  EXPIRED = "EXPIRED",
  DELETED = "DELETED",
  CANCELLED = "CANCELLED",
}

export enum OnboardingApplicationSectionStatus {
  WARNING = "WARNING",
  COMPLETED = "COMPLETED",
}

export enum OnboardingTemplatePublishStatus {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED",
}

export enum OnboardingTemplateStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  EXPIRED = "EXPIRED",
}

export enum OnboardingFormSectionName {
  formPreparation = "formPreparation",
  confirmationQuestions = "confirmationQuestions",
  dataCollectionAndConsent = "dataCollectionAndConsent",
  eSignatureSection = "eSignatureSection",
  termsNConditionsSection = "termsNConditionsSection",
  documentUploadSection = "documentUploadSection",
}

export enum OnboardingFormQuestionName {
  candidateSignature = "candidateSignature",
  recruiterSignature = "recruiterSignature",
  recruiterIdentityDocumentType = "recruiterIdentityDocumentType",
  recruiterIdentityDocumentUpload = "recruiterIdentityDocumentUpload",
  agentLeaderSignature = "agentLeaderSignature",
  agentLeaderDocumentType = "agentLeaderDocumentType",
  agentLeaderDocumentUpload = "agentLeaderDocumentUpload",
}

export enum RoleEnum {
  USER = "USER",
  AGENT = "AGENT",
  /**
   * Only invitations, no candidate data submitted
   */
  PENDING_CANDIDATE = "PENDING_CANDIDATE",
  CANDIDATE = "CANDIDATE",
  MANAGER = "MANAGER",
  STAFF = "STAFF",

  SECRETARY = "SECRETARY",
}

export interface OnboardingFormData {
  candidateId?: string;
  templateId: string;
  moduleName?: OnboardingFormModuleName;
  applicationId?: string;
  sections?: {
    sectionName: string;
    questionAnswers: Record<string, boolean | number | string | Array<boolean | number | string>>;
  }[];
  additionalInfo?: any;
}

export interface SubmitFormActionParams {
  data: OnboardingFormData;
  action?: FormAction;
}

export enum FormAction {
  INIT = "INIT",
  REJECT = "REJECT",
  REVISE = "REVISE",
  VALIDATION = "VALIDATION",
  SAVE = "SAVE",
  SUBMIT = "SUBMIT",
  LOCK = "LOCK",
  RE_SUBMIT = "RE_SUBMIT",
  WITHDRAW = "WITHDRAW",
  FORWARD = "FORWARD",
}
export interface OnboardingTrainingCourse {
  courseId: string;
  readonly?: boolean;
  name: string;
  type: "ONBOARDING_TRAINING";
  code: string;
  status: string;
  lessons: OnboardingLesson[];
  quiz?: OnboardingCourseQuiz;
}

export enum OnboardingLessonStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
}

export enum OnboardingQuizStatus {
  DISABLED = "DISABLED",
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  PASSED = "PASSED",
  FAILED = "FAILED",
}

export enum OnboardingExamRegistryStatus {
  // @deprecated
  REGISTERED = "REGISTERED",
  // @deprecated
  NOT_REGISTERED = "NOT_REGISTERED",
  // @deprecated
  PASSED = "PASSED",
  // @deprecated
  FAILED = "FAILED",
  // below are new status
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  TIMESLOT_CANCELLED = "TIMESLOT_CANCELLED",
  COMPLETED = "COMPLETED",
}

export interface OnboardingLesson {
  lessonId: string;
  name: string;
  courseName: string;
  code: string;
  materialUpload: string;
  status: OnboardingLessonStatus;
  quiz?: OnboardingQuiz;
  readonly?: boolean;
  hasNext?: boolean;
  courseQuiz?: OnboardingQuiz;
}

export interface OnboardingQuizBase {
  courseId: string;
  quizId: string;
  lessonId?: string;
  candidateId?: string;
  readonly?: boolean;
}

export interface OnboardingQuiz extends OnboardingQuizBase {
  name: string;
  status: OnboardingQuizStatus;
  type?: "COURSE_QUIZ" | "LESSON_QUIZ";
  code: string;
  score?: number;
  passingScore?: number;
  passedCount?: number;
  failedCount?: number;
  maxFailCount?: number;
  questions?: Question[];
}

type OnboardingCourseQuiz = OnboardingQuiz;

export interface Timeslot {
  name: string;
  value: string;
  remainingQuota: number;
}
export interface OnboardingRegistrationInfo {
  examCentre: string;
  examScheduleId: string;
  examDate: string;
  startTime: string;
  endTime: string;
}
export interface OnboardingExamResult {
  isPresent: boolean;
  result: "PASSED" | "FAILED";
  score: number | string;
}

// all exam registration template
export interface OnboardingTypeExam {
  type: string;
  exams: OnboardingExam[];
}

interface Summary {
  requiredToAttend?: boolean;
  status: StatusEnum;
  paymentStatus?: PaymentStatus;
  required?: number;
  completed: number;
  total?: number;
}

export interface OnboardingTrainingSummary {
  trainingSummary: Summary;
  examSummary: Summary;
}

// single exam registration template
export interface OnboardingExam {
  name: string;
  type: string;
  status: OnboardingExamRegistryStatus;
  score: number;
  id: string;
  examResult?: OnboardingExamResult | string;
  registeredInfo?: OnboardingRegistrationInfo;
  readonly?: boolean;
  originData?: RegistrationDTO;
}
export interface OnboardingRegistrationData {
  examCentre: string;
  timeslot: Timeslot;
}
// candidate onboarding exam data submitted
export interface OnboardingExamData {
  name: string;
  isRegistered: boolean;
  registrationInfo?: OnboardingRegistrationData;
}
export enum PaymentType {
  FullPayment = "FULL_PAYMENT",
  CodeNowPayLater = "CODE_NOW_PAY_LATER",
}
export enum PaymentModule {
  Training = "TRAINING",
  Exam = "EXAM",
}

export enum PaymentStatus {
  PENDING_APPROVAL = "PENDING_APPROVAL",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  RETURNED = "RETURNED",
  SUBMITTED = "SUBMITTED",
  POPULATED = "POPULATED",
}

export enum OnboardingPreCheck {
  LicenseExam = "LicenseExam",
  Training = "Training",
}

export enum StatusEnum {
  PENDING_APPROVAL = "PENDING_APPROVAL",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  RETURNED = "RETURNED",
  SUBMITTED = "SUBMITTED",
  POPULATED = "POPULATED",
  COMPLETED = "COMPLETED",
  NOT_STARTED = "NOT_STARTED",
}
export interface RegistrationDTO {
  registrationId?: string;
  hasPassedExam?: boolean;
  examCentre?: string;
  examType?: string;
  examCode?: string;
  examName?: any;
  licenseInfo?: LicenseInfoDTO;
  examScheduleId?: string;
  examPayment?: ExamPaymentDTO;
  examSchedule?: ExamScheduleDTO;
  registrationApprovalStates?: RegistrationApprovalStatus;
  registrationStatus?: RegistrationStatus;
  licenseApprovalStates?: LicenseStatus;
}

export interface LicenseInfoDTO {
  region?: string;
  channel?: string;
  id?: string;
  userId?: string;
  firstname?: string;
  lastname?: string;
  userRole?: RoleEnum;
  applicationFormId?: string;
  licenseApprovalStates?: PaymentStatus;
  examType?: string;
  examName?: any;
  examCode?: string;
  hasLicenseProof?: boolean;
  examScore?: string;
  examDate?: string;
  proofInfos?: ProofInfoDTO[];
  officialReceiptNumber?: string;
  paymentReceiptDate?: string;
  operationHistoryList?: OperationHistoryDTO[];
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  isDeleted?: boolean;
}

export interface ProofInfoDTO {
  resourceId?: string;
  fileName?: string;
  fileType?: string;
  fileSize?: number;
  createdAt?: string;
}

export interface OperationHistoryDTO {
  createdBy?: string;
  createdByRole?: RoleEnum;
  createdAt?: string;
  lastUpdatedBy?: string;
  lastUpdatedByRole?: RoleEnum;
  lastUpdatedAt?: string;
  status?: string;
  comment?: string;
}

export interface ExamPaymentDTO {
  examRegistrationId?: string;
  paymentId?: string;
  paymentType?: PaymentType;
  licenseFeeReceiptNo?: string;
  licenseFeeReceiptDate?: string;
  examFeeReceiptNo?: string;
  examFeeReceiptDate?: string;
}

export interface ExamScheduleDTO {
  region?: string;
  channel?: string;
  id?: string;
  examType?: string;
  examCode?: string;
  examName?: any;
  examDate?: string;
  startTime?: string;
  endTime?: string;
  examCentre?: string;
  address?: string;
  lastRegistrationDate?: string;
  quota?: number;
  remainingQuota?: number;
  minimumQuota?: number;
  isDeleted?: boolean;
  createdBy?: string;
  updatedBy?: string;
  createdDate?: string;
  lastUpdatedDate?: string;
  remarks?: string;
}

export enum RegistrationStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  TIMESLOT_CANCELLED = "TIMESLOT_CANCELLED",
  COMPLETED = "COMPLETED",
}
export enum RegistrationApprovalStatus {
  PENDING_APPROVAL = "PENDING_APPROVAL",
  REGISTERED = "REGISTERED",
  REJECTED = "REJECTED",
  RETURNED = "RETURNED",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  NOT_STARTED = "NOT_STARTED",
}

export enum LicenseStatus {
  NOT_STARTED = "NOT_STARTED",
  PENDING_APPROVAL = "PENDING_APPROVAL",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  RETURNED = "RETURNED",
  CANCELLED = "CANCELLED",
}
export enum ExamStatus {
  PASS = "PASSED",
  FAIL = "FAILED",
  NOT_STARTED = "NOT_STARTED",
  CANCELLED = "CANCELLED",
}
