import React from "react";
import PageContainer from "../../../../components/page-container/page-container";
import { PasswordInput } from "../../../../components/password-input/password-input.component";
import { useTranslation } from "react-i18next";
import { useSetPassword } from "./set-password.hook";

export const SetPasswordScreen = () => {
  const { t } = useTranslation();
  const { password, setPassword, onConfirm, errorMessages } = useSetPassword();

  return (
    <PageContainer title={t("Common.set_password")}>
      <div className="flex flex-1 flex-col max-w-md sm:max-w-xl lg:max-w-xl">
        <div className="flex flex-col w-full pb-4 px-8">
          <div className="bg-white w-full rounded-tr-3xl h-10"></div>
          <PasswordInput
            label={t("Recruitment.new_password")}
            value={password}
            onChange={setPassword}
            placeholder={t("Common.please_enter")}
            showError={true}
            errorMessages={errorMessages}
          />
        </div>

        <div className=" w-full bg-white pt-2 pb-7 flex items-center justify-between gap-4 px-8">
          <button
            className="bg-red-500 flex-1 text-white font-semibold py-3 rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75"
            onClick={onConfirm}
          >
            {t("global.text.confirm")}
          </button>
        </div>
      </div>
    </PageContainer>
  );
};
