import React, { useState } from "react";
import { getI18n } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import { findText } from "./find-text";

export type ModalData = { value?: string; label: string; key?: string };
export type FullModalListProps = {
  // default false
  showSearch?: boolean;
  // use key to identify, if don't have key will use value
  data?: ModalData[];
  /**
   * selected key
   */
  selected?: string;
  header?: string;
  showClose?: boolean;
  searchPlaceHolder?: string;
  onClose?: () => void;
  onChange?: (d: ModalData) => void;
};
export const FullModalList = (props: FullModalListProps) => {
  const { showSearch } = props;
  const placeholder = props.searchPlaceHolder ?? getI18n().t("Common.search_by_keyword_or_code");
  const [searchKey, setKey] = useState("");
  return (
    <div className="flex flex-1 flex-col">
      <div className="items-center flex pl-2.5 pr-5 py-2">
        <IconButton sx={{ p: "10px" }} aria-label="directions" onClick={props.onClose}>
          <CloseIcon className="text-base font-semibold" style={{ color: "#333" }} />
        </IconButton>
        <span className="text-xl font-semibold">
          {props.header ?? getI18n().t("Please select")}
        </span>
      </div>
      {showSearch ? (
        <div
          className="flex flex-row items-center mx-4 px-4 py-2 bg-neutral-100 h-5 my-1 h-9"
          style={{ borderRadius: 18 }}
        >
          <SearchIcon className="text-base" style={{ color: "#999" }} />
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={placeholder}
            inputProps={{ className: "!text-sm !font-medium !p-0" }}
            style={{ color: "#333" }}
            value={searchKey}
            onChange={(e) => {
              setKey(e.target.value.trim());
            }}
          />
          {!!searchKey && (
            <IconButton
              sx={{ p: "10px" }}
              aria-label="directions"
              className="!p-0"
              onClick={() => {
                setKey("");
              }}
            >
              <CancelSharpIcon className="text-base" />
            </IconButton>
          )}
        </div>
      ) : null}
      <div className="mt-2 px-5 flex flex-1 w-full">
        <List className="w-full">
          {props.data
            ?.filter((d) => d.label.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()))
            .map((d, index) => {
              return (
                <ListItem
                  component="div"
                  className="!p-0"
                  style={{ borderBottom: "1px solid #f0f0f0" }}
                  key={index}
                >
                  <ListItemButton
                    className="!px-0 !pt-4 !pb-4 "
                    onClick={() => {
                      props.onChange?.(d);
                      props.onClose?.();
                    }}
                  >
                    <span>
                      {findText(d.label, searchKey).map((s, index) => {
                        return (
                          <span
                            className="!text-sm !font-medium whitespace-pre"
                            key={index}
                            style={{
                              color:
                                s.highlight ||
                                (!!d.key && !!props.selected && d.key === props.selected)
                                  ? "#E8192C"
                                  : "#333",
                            }}
                          >
                            {s.text}
                          </span>
                        );
                      })}
                    </span>
                  </ListItemButton>
                </ListItem>
              );
            })}
        </List>
      </div>
    </div>
  );
};
