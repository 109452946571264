import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ONBOARDING_PATH, ROOT_PATH } from "../../../../routes/constants";
import { createCandidateFunc } from "./create-candidate";
import { useDispatch } from "react-redux";
import { loginAsync } from "../../../auth/redux/auth-slice";
import { OtpContextEnum, UserTypeEnum } from "../../../common/network/enum";
import { useTranslation } from "react-i18next";

export const useSetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user: any = useMemo(() => {
    return location.state?.user;
  }, [location.state?.user]);

  const [password, setPassword] = useState("");
  const [errorMessages, setErrorMessages] = useState<string>("");

  // TODO: Move password validation to common utils
  const validatePassword = (password: string) => {
    return (
      password.length >= 8 &&
      password.length <= 16 &&
      /[0-9]/.test(password) &&
      /[a-z]/.test(password) &&
      /[A-Z]/.test(password)
    );
  };

  const onConfirm = async () => {
    const acceptableChar = `^[a-zA-Z0-9!@#$%~*&^]+$`;
    if (!new RegExp(acceptableChar).test(password)) {
      setErrorMessages(t("Recruitment.set_password_symbol"));
      return;
    } else {
      if (!password || !validatePassword(password)) {
        return;
      }
      setErrorMessages("");
    }
    const res = await createCandidateFunc({
      username: user?.username,
      password,
      context: user?.context,
      otp: user?.otp,
    });
    if (!res) {
      return;
    } else {
      await dispatch<any>(
        loginAsync({ username: user?.username, password, userType: UserTypeEnum.CANDIDATE }),
      );
      if (user?.context === OtpContextEnum.FORGOT_PASSWORD) {
        navigate(`${ROOT_PATH}/login`);
      } else {
        navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/join-now`, {
          state: { userName: user?.username },
        });
      }
    }
  };

  return {
    password,
    setPassword,
    onConfirm,
    errorMessages,
  };
};
