import React, { useState } from "react";
import openIcon from "../../components/icon/assets/preview-close.svg";
import closeIcon from "../../components/icon/assets/preview-open.svg";
import wrongIcon from "../../components/icon/assets/wrong.svg";
import rightIcon from "../../components/icon/assets/right.svg";
import { useTranslation } from "react-i18next";
import "./password-input.component.css";

interface PasswordInputProps {
  label?: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  showError?: boolean;
  errorMessages?: string;
  className?: string;
}

export const PasswordInput: React.FC<PasswordInputProps> = ({
  value,
  onChange,
  placeholder,
  label,
  showError,
  errorMessages,
  className,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [isNumberValid, setIsNumberValid] = useState(false);
  const [isCaseValid, setIsCaseValid] = useState(false);
  const { t } = useTranslation();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const valueChange = (password: string) => {
    validatePassword(password); // Validate the new password
    onChange(password);
  };
  const validatePassword = (password: string) => {
    // 1. Check length
    setIsLengthValid(password.length >= 8 && password.length <= 16);
    // 2. Check for at least one number
    setIsNumberValid(/[0-9]/.test(password));
    // 3. Check for both upper and lower case letters
    setIsCaseValid(/[a-z]/.test(password) && /[A-Z]/.test(password));
  };

  return (
    <div className={`mb-4 ${className}`}>
      {label && (
        <label
          htmlFor="password"
          className="block font-semibold text-gray-800 font-body text-sm leading-5 mb-1"
        >
          {label}
        </label>
      )}
      <div className="flex items-center w-full border-b border-gray-300 focus:outline-none pb-2">
        <input
          type={showPassword ? "text" : "password"}
          className="w-full border-none focus:outline-none"
          placeholder={placeholder}
          value={value}
          onChange={(e) => valueChange(e.target.value)}
          required
        />
        <span onClick={togglePasswordVisibility}>
          <img src={showPassword ? openIcon : closeIcon} alt="" className="w-5 h-5" />
        </span>
      </div>
      {errorMessages && <div className="text-[#E8192C] text-xs">{errorMessages}</div>}
      {showError && (
        <div className="mt-6">
          <div className="flex w-full pb-2">
            <img src={isLengthValid ? rightIcon : wrongIcon} alt="" className="w-5 h-5" />
            <label className="font-sf-pro-text text-sm font-medium leading-5 text-left text-[#666666] ml-6">
              {t("Recruitment.onboarding_login_password_rule_1")}
            </label>
          </div>
          <div className="flex w-full pb-2">
            <img src={isNumberValid ? rightIcon : wrongIcon} alt="" className="w-5 h-5" />
            <label className="font-sf-pro-text text-sm font-medium leading-5 text-left text-[#666666] ml-6">
              {t("Recruitment.onboarding_login_password_rule_2")}
            </label>
          </div>
          <div className="flex w-full pb-2">
            <img src={isCaseValid ? rightIcon : wrongIcon} alt="" className="w-5 h-5" />
            <label className="font-sf-pro-text text-sm font-medium leading-5 text-left text-[#666666] ml-6">
              {t("Recruitment.onboarding_login_password_rule_3")}
            </label>
          </div>
        </div>
      )}
    </div>
  );
};
