import { getI18n } from "react-i18next";
import { get, isString } from "lodash";
import { http } from "../../../utils/axios/axios";
import {
  FormAction,
  OnboardingExam,
  OnboardingFormData,
  OnboardingLessonStatus,
  OnboardingQuiz,
  OnboardingQuizBase,
  PaymentModule,
  PaymentType,
} from "../types";
import {
  COMMON_PUBLIC_FRONTEND_ENDPOINT,
  CORE_URL,
  baseURL,
  fakeToken,
  getI18nTranslation,
  getToken,
  parseJwt,
} from "../utils";
import { DeleteFile, DocumentUpload, DownLoadFile, FileUpload } from "./file-op-interface";
import store from "../../../redux/store";
import { PruToast } from "../../../components/pru-toast";
import { ROOT_PATH, ONBOARDING_PATH } from "../../../routes/constants";
import { ApplicationType } from "../pages/application-detail-screen/template";
import { I18nModule, translate } from "../../../i18n/translation";
import { Buffer } from "buffer";
import { handleSurveyError } from "./network.util";
import moment from "moment";
import { OtpContextEnum, UserTypeEnum } from "../../common/network/enum";

// Helper function to retry getting the token
const retryGetToken = (maxRetries = 3, interval = 500) => {
  return new Promise((resolve, reject) => {
    let attempts = 0;
    let retryTimeout: NodeJS.Timeout;

    const attempt = () => {
      const token = getToken();
      if (token !== fakeToken) {
        clearTimeout(retryTimeout); // Clear the timeout if token is received
        resolve(token); // Resolve the promise with the token
      } else if (attempts >= maxRetries) {
        reject(new Error("Failed to retrieve token after maximum attempts.")); // Reject the promise after max retries
      } else {
        attempts++;
        retryTimeout = setTimeout(attempt, interval); // Set timeout for next attempt
      }
    };

    attempt(); // Initiate the first token retrieval attempt
  });
};

// Enhanced getCustomHeader function with retry logic
export const getCustomHeaderAsync = async (): Promise<any> => {
  try {
    const token = await retryGetToken(); // Attempt to get the token with retry logic
    return {
      "Ocp-Apim-Subscription-Key": get(window, "envConfig.REACT_APP_OEM_APIM_SUBSCRIPTION_KEY", ""),
      Authorization: token,
      "Accept-Language": mapLanguage(getI18n().language),
    };
  } catch (error) {
    // Handle the error as desired, e.g., return error headers or throw the error
    return {};
  }
};

export const getCustomHeader = () => {
  return {
    "Ocp-Apim-Subscription-Key": get(window, "envConfig.REACT_APP_OEM_APIM_SUBSCRIPTION_KEY", ""),

    Authorization: getToken(),
    "Accept-Language": mapLanguage(getI18n().language),
  };
};

export function mapSurveyLanguage(lang: string): string {
  const langkey = lang.toLocaleLowerCase();
  const langMapping: Record<string, string> = {
    "zh-hant": "zh-tw",
    zhhk: "zh-tw",
  };
  return langMapping[langkey] || langkey;
}
export function mapLanguage(lang: string): string {
  const langkey = lang.toLocaleLowerCase();
  const langMapping: Record<string, string> = {
    zhhk: "zh-Hant",
  };
  return langMapping[langkey] || langkey;
}
export const fetchOnboardingFormTemplate = async (params: any): Promise<any> => {
  const language = mapSurveyLanguage(getI18n().language ?? "en");
  const response = await http.get(`${baseURL}/onboarding/form-template`, {
    headers: await getCustomHeaderAsync(),
    params: {
      ...params,
      language,
    },
  });
  return response;
};

export const fetchCandidateOnboardingForm = async (candidateId: string): Promise<any> => {
  // const form = await import("./form.json");
  // return { data: {} };
  const response = await http.get(`${baseURL}/onboarding/forms?candidateId=${candidateId}`, {
    headers: await getCustomHeaderAsync(),
  });
  return response;
};

export const fetchOnboardingDocumentUpload = async (body: DocumentUpload): Promise<any> => {
  const response = await http.get(`${baseURL}/onboarding/documentUpload/file`, {
    headers: await getCustomHeaderAsync(),
    params: body,
  });
  return response;
};

export const submitApplicationForm = async (
  data: OnboardingFormData,
  action?: FormAction,
): Promise<any> => {
  const language = mapSurveyLanguage(getI18n().language ?? "en");
  const actionQuery = action ? `formAction=${action}` : "";
  const response = await http
    .post(`${baseURL}/onboarding/forms?${actionQuery}&lang=${language}`, data, {
      headers: { ...(await getCustomHeaderAsync()), __loading: true },
    })
    .then(
      (res) => res,
      (err) => {
        return err?.response?.data ?? { errors: [{ message: "Unknown error" }], statusCode: 500 };
      },
    );
  return response;
};

export const fetchOnboardingFormDeriveData = async (params: any): Promise<any> => {
  if (params.triggerKey === "agentProfile") {
    params.agentCode = store.getState()?.onboarding?.candidateOnboardingForm?.recruiterCode;
  }
  const url = new URL(`${baseURL}${params.path}?triggerKey=${params.triggerKey}`);
  if (params.agentCode) {
    url.searchParams.append("agentCode", params.agentCode);
  }
  if (params.derivedValue) {
    url.searchParams.append("derivedValue", params.derivedValue);
  }
  const response = await http.get(url.href, {
    headers: await getCustomHeaderAsync(),
  });
  return response;
};

export const fetchConfig = async (): Promise<any> => {
  const rawToken = getToken();
  const tokenData = rawToken ? parseJwt(rawToken) : {};
  const namespace =
    tokenData?.region && tokenData?.channel
      ? `${tokenData.region}.${tokenData.channel}`
      : "default";
  const configPaths = [
    `${CORE_URL}/configs/recruitment-common?group=pruforce.Recruitment&namespace=${namespace}`,
    `${CORE_URL}/configs/recruitment-sdk?group=pruforce.Recruitment&namespace=${namespace}`,
    `${CORE_URL}/configs/global-system?group=pruforce.Global&namespace=default&access=public`,
  ];
  const headers = await getCustomHeaderAsync();
  const responses = await Promise.all(
    configPaths.map((path) =>
      http.get(path, {
        headers,
      }),
    ),
  );
  return responses;
};

export const fetchGlobalConfig = async (): Promise<any> => {
  const response = await http.get(
    `/configs/global-system?group=pruforce.Global&namespace=default&access=public`,
  );
  return response;
};
export const fetchRecruitmentPublicConfig = async (): Promise<any> => {
  const response = await http.get(
    `/configs/recruitment-public?group=pruforce.Recruitment&namespace=default&access=public`,
  );
  return response;
};

export const uploadFile = async (body: FileUpload): Promise<any> => {
  const response = await http.post(`${baseURL}/onboarding/file`, body, {
    headers: await getCustomHeaderAsync(),
  });
  return response;
};

export const getTodoTasks = async (params: {
  applicationId: string;
  page?: number;
  limit?: number;
  type?: string;
}): Promise<any> => {
  const { applicationId, page = 0, limit = 10, type } = params;
  /**
   * disable first
   */
  const response = await http.get(`${baseURL}/onboarding/todo-task`, {
    /**
     * for self test
     */
    // const response = await http.get(`http://localhost:3000/onboarding/todo-task`, {
    headers: await getCustomHeaderAsync(),
    params: {
      applicationId,
      page,
      // type: "FOLLOWUP_REQUIRED", // PENDING_SIGNATURE | FOLLOWUP_REQUIRED
      ...(type ? { type } : {}),
      /**
       * for self test
       */
      // agentCode: "00010009",
      limit,
    },
  });
  return response;
};
export const downFile = async (body: DownLoadFile): Promise<any> => {
  const response = await http.get(`${baseURL}/onboarding/file`, {
    headers: await getCustomHeaderAsync(),
    params: body,
  });
  return response;
};
export const deleteFile = async (body: DeleteFile): Promise<any> => {
  const response = await http.delete(`${baseURL}/onboarding/file`, {
    headers: await getCustomHeaderAsync(),
    params: body,
  });
  return response;
};

export async function getInvitationStatus(id: string): Promise<any> {
  const url = `${COMMON_PUBLIC_FRONTEND_ENDPOINT}/api/recruitment/pca/invitations/${id}`;
  return http
    .get(url, { headers: { ...(await getCustomHeaderAsync()), __loading: true } })
    .then((res: any) => res);
}

export async function getInvitationsByUserName(username: string): Promise<any> {
  const url = `${baseURL}/invitations/me?phone=${encodeURIComponent(username)}`;
  return http
    .get(url, { headers: { ...(await getCustomHeaderAsync()), __loading: true } })
    .then((res: any) => res);
}

export async function inviteCandidate(param: any, navigate: any) {
  const regionCode = Array.isArray(param.regionCode) ? param.regionCode[0] : param.regionCode;

  const data = {
    firstname: param.firstname,
    lastname: param.lastname,
    gender: param.gender,
    birthday: param.birthday,
    identityDocumentType: param.identityDocumentType,
    identityDocumentNumber: param.identityDocumentNumber,
    // phone: `${regionCode} ${param.phoneNumber.replace(/ /g, "")}`, // phone can't change here, so we don't need transfer it
    email: param.email,
    languagePreference: param.languagePreference,
    reviewedAndAgreeToPICS: true,
    applicationDetails: param.applicationDetails,
    pageRoute: "recruitmentcandidateprofile",
  };
  const url = `${COMMON_PUBLIC_FRONTEND_ENDPOINT}/api/recruitment/invitations/${param.inviteId}/candidates`;
  http
    .post(url, data, { headers: { "agent-code": param.agentCode } })
    .then((res) => {
      PruToast({ message: getI18n().t("Submit successfully") });
      setTimeout(() => {
        navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/join-done`);
      }, 2000);
    })
    .catch((err) => {
      console.log("err===", err);
      const errorKey = get(err, "response.data.message");
      if (errorKey) {
        const msg = getI18n().t(`Recruitment.${errorKey}`);
        if (msg && !msg.startsWith("Recruitment.")) {
          PruToast({ message: msg });
          return;
        }
      }
      if (err.status === 409) {
        PruToast({ message: getI18n().t("You have been the candidate. Please don't resubmit.") });
      } else if (err.status === 400) {
        PruToast(err?.data?.message);
      } else if (err.status === 410) {
        PruToast({ message: getI18n().t("Invitation Link Expired") });
      } else if (err.status === 412) {
        PruToast({ message: getI18n().t("You are already a PRU agent.") });
      }
    });
}

export async function inviteCandidateEdit(param: any, navigate: any) {
  const data = {
    firstname: param.firstname,
    lastname: param.lastname,
    gender: param.gender,
    birthday: param.birthday,
    email: param.email,
    languagePreference: param.languagePreference,
    reviewedAndAgreeToPICS: true,
    pageRoute: "recruitmentcandidateprofile",
  };
  const url = `${COMMON_PUBLIC_FRONTEND_ENDPOINT}/api/recruitment/invitations/${param.inviteId}/candidates`;
  http
    .patch(url, data, { headers: { "agent-code": param.agentCode } })
    .then((res) => {
      PruToast({ message: getI18n().t("Submit successfully") });
      setTimeout(() => {
        navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/join-done`);
      }, 2000);
    })
    .catch((err) => {
      console.log("err===", err);
      const errorKey = get(err, "response.data.message");
      if (errorKey) {
        const msg = getI18n().t(`Recruitment.${errorKey}`);
        if (msg && !msg.startsWith("Recruitment.")) {
          PruToast({ message: msg });
          return;
        }
      }
      if (err.status === 400) {
        PruToast(err?.data?.message);
      } else if (err.status === 410) {
        PruToast({ message: getI18n().t("Invitation Link Expired") });
      } else if (err.status === 412) {
        PruToast({ message: getI18n().t("You are already a PRU agent.") });
      }
    });
}

export async function submitCandidateData(params: {
  candidateData: any;
  navigate: any;
  recruitPublicConfigs: any;
  globalConfigs: any;
}) {
  const { candidateData, navigate, recruitPublicConfigs, globalConfigs } = params;

  const submitData: any = {
    firstname: candidateData.firstname,
    lastname: candidateData.lastname,
    gender: candidateData.gender,
    phone: `${candidateData.regionCode} ${candidateData.phoneNumber}`,
    email: candidateData.email,
    recruiterCode: candidateData.agentCode,
    ...(recruitPublicConfigs?.candidateProfile?.enableBirthday
      ? { birthday: moment(candidateData.birthday).format("DD/MM/YYYY") }
      : {}),
    reviewedAndAgreeToPICS: candidateData.isAgreedPolicy,
    languagePreference: candidateData.languagePreference,
    ...(recruitPublicConfigs?.disableCandidateDataIdDocCheck
      ? {}
      : {
          identityDocumentType: candidateData.identityDocumentType,
          identityDocumentNumber: candidateData.identityDocumentNumber,
        }),
  };

  if (candidateData.applicationDetails) {
    submitData.applicationDetails = {
      ...candidateData.applicationDetails,
    };
  }
  const url = `${baseURL}/invitations/me/${candidateData.invitationId}?recruiterCode=${candidateData.agentCode}`;
  return http
    .post(url, submitData, {
      headers: {
        ...(await getCustomHeaderAsync()),
        __loading: true,
      },
    })
    .then((res) => {
      PruToast({ message: getI18n().t("Submit successfully") });
      return res;
    })
    .catch((err) => {
      console.log("err===", err);
      const errorKey = get(err, "response.data.message");
      PruToast({ message: getI18nTranslation(errorKey) ?? errorKey });
    });
}

export async function updateCandidateProfile(params: {
  candidateData: any;
  navigate: any;
  recruitPublicConfigs: any;
  globalConfigs: any;
}) {
  const { candidateData } = params;

  const submitData: any = candidateData;

  if (candidateData.applicationDetails) {
    submitData.applicationDetails = {
      ...candidateData.applicationDetails,
    };
  }
  const url = `${baseURL}/candidates/me/${candidateData.id}`;
  return http
    .put(url, submitData, {
      headers: {
        ...(await getCustomHeaderAsync()),
        __loading: true,
      },
    })
    .then((res) => {
      PruToast({ message: getI18n().t("Common.updated_successfully") });
      return res;
    })
    .catch((err) => {
      console.log("err===", err);
      const errorKey = get(err, "response.data.message");
      PruToast({ message: getI18nTranslation(errorKey) ?? errorKey });
    });
}
export const quizAnswerUpdate = async (quiz: OnboardingQuiz, body: any): Promise<any> => {
  const data = {
    questionName: body.name,
    lang: mapLanguage(getI18n().language),
    answers: [body.value],
    endQuiz: body.endQuiz,
    lessonId: quiz.lessonId,
  };
  const response = await http.post(
    `${baseURL}/onboarding/training/candidates/${quiz.candidateId}/courses/${quiz.courseId}/quizzes/${quiz.quizId}/answers`,
    data,
    {
      headers: await getCustomHeaderAsync(),
      params: {
        language: mapLanguage(getI18n().language),
      },
    },
  );
  return response;
};

export const lessonStatusUpdate = async (body: any): Promise<any> => {
  const data = {
    status: body.status,
  };
  const response = await http.patch(
    `${baseURL}/onboarding/training/candidates/${body.candidateId}/courses/${body.courseId}/lessons/${body.lessonId}/status`,
    data,
    {
      headers: await getCustomHeaderAsync(),
    },
  );
  return response;
};

export const fetchTrainingCourses = async ({
  candidateId,
}: {
  candidateId: string;
}): Promise<any> => {
  // const courses = await import("./courses.json");
  // return courses;
  const response = await http.get(
    `${baseURL}/onboarding/training/candidates/${candidateId}/courses`,
    {
      headers: { ...(await getCustomHeaderAsync()), __loading: true },
      params: {
        language: mapLanguage(getI18n().language),
      },
    },
  );
  return response;
};

export const fetchTrainingCourseQuizDetail = async ({
  courseId,
  quizId,
  lessonId,
  candidateId,
}: OnboardingQuizBase & { candidateId: string }): Promise<any> => {
  // const quizDetail = await import("./quiz-content.json");
  // return quizDetail;
  const response = await http.get(
    `${baseURL}/onboarding/training/candidates/${candidateId}/courses/${courseId}/quizzes/${quizId}/detail?lessonId=${lessonId}`,
    {
      headers: { ...(await getCustomHeaderAsync()), __loading: true },
      params: {
        language: mapLanguage(getI18n().language),
      },
    },
  );
  return response;
};

export const updateCourseQuizRandomQuestion = async ({
  courseId,
  quizId,
  lessonId,
  candidateId,
}: OnboardingQuizBase & { candidateId: string }): Promise<any> => {
  const response = await http.patch(
    `${baseURL}/onboarding/training/candidates/${candidateId}/courses/${courseId}/quizzes/${quizId}/questions?lessonId=${lessonId}`,
    {},
    {
      headers: { ...(await getCustomHeaderAsync()), __loading: true },
      params: {
        language: mapLanguage(getI18n().language),
      },
    },
  );
  return response;
};

export const getOnboardingTrainingSummary = async ({
  candidateId,
}: {
  candidateId: string;
}): Promise<any> => {
  const response = await http.get(
    `${baseURL}/onboarding/training/candidates/${candidateId}/summary`,
    {
      headers: await getCustomHeaderAsync(),
      params: {
        language: mapLanguage(getI18n().language),
      },
    },
  );
  return response;
};

export const fetchTrainingExams = async ({
  candidateId,
}: {
  candidateId: string;
}): Promise<any> => {
  // const exams = await import("./exams.json");
  // return exams;
  const response = await http.get(
    `${baseURL}/onboarding/exam/candidates/${candidateId}/registrations`,
    {
      headers: { ...(await getCustomHeaderAsync()), __loading: true },
      params: {
        language: mapLanguage(getI18n().language),
      },
    },
  );
  return response;
};

export const fetchTrainingExamCentres = async (params: OnboardingExam): Promise<any> => {
  const response = await http.get(`${baseURL}/onboarding/exam/schedules/examCentres/available`, {
    headers: await getCustomHeaderAsync(),
    params: {
      examCode: params.id,
      language: mapLanguage(getI18n().language),
    },
  });
  return response;
};

export const fetchTrainingExamTimeslots = async (
  params: OnboardingExam & { examCentre: string },
): Promise<any> => {
  const response = await http.get(`${baseURL}/onboarding/exam/schedules/timeslots/available`, {
    headers: await getCustomHeaderAsync(),
    params: {
      examCode: params.id,
      examCentre: params.examCentre,
      language: mapLanguage(getI18n().language),
    },
  });
  return response;
};

export const postCandidateExamRegistry = async (candidateId: string, body: any): Promise<any> => {
  const response = await http
    .post(`${baseURL}/onboarding/exam/candidates/${candidateId}/registrations`, body, {
      headers: await getCustomHeaderAsync(),
      params: {
        lang: mapLanguage(getI18n().language),
      },
    })
    .catch((err) => {
      return err.response.data;
    });
  return response;
};

export async function submitApplicationDetail(body: any, survey: any, navigateCallback: any) {
  const goBack = () => {
    window.ReactNativeWebView?.postMessage?.(
      `{ "action" : "onSubmit", "data": ${JSON.stringify(body)}}`,
    );
    navigateCallback();
  };
  const url = `${baseURL}/proxy/invitations/validation`;
  const headers = await getCustomHeaderAsync();
  if (!headers.Authorization) {
    goBack();
    return;
  }
  return http
    .post(url, body, {
      headers,
      params: {
        lang: mapLanguage(getI18n().language),
      },
    })
    .then((res: any) => {
      goBack();
    })
    .catch((err) => {
      const errors = get(err, "response.data.errors");
      const message = get(err, "response.data.message", "Unknown error");
      handleSurveyError({ errors, survey, defaultErrMsg: message });
    });
}

export async function submitTrainingPayment(
  candidateId: string,
  body: any,
  survey: any,
  navigateCallback: any,
) {
  const goBack = (params?: any) => {
    navigateCallback(params);
  };
  const url = `${baseURL}/proxy/onboarding/payments/training/candidates/${candidateId}`;
  const headers = await getCustomHeaderAsync();
  if (!headers.Authorization) {
    goBack();
    return;
  }
  return http
    .post(url, body, {
      headers,
      params: {
        lang: mapLanguage(getI18n().language),
      },
    })
    .then((res: any) => {
      goBack(res);
    })
    .catch((err) => {
      const errors = get(err, "response.data.errors");
      const message = get(err, "response.data.message", "Unknown error");
      handleSurveyError({ errors, survey, defaultErrMsg: message });
    });
}

export async function getTrainingPayment(candidateId: string) {
  const url = `${baseURL}/proxy/onboarding/payments/training/candidates/${candidateId}`;
  const headers = await getCustomHeaderAsync();
  if (!headers.Authorization) {
    return;
  }
  return http
    .get(url, {
      headers,
      params: {
        lang: mapLanguage(getI18n().language),
      },
    })
    .then((res: any) => {
      return {
        data: res.data?.filter((item: any) => item.paymentModule === PaymentModule.Training)[0],
      };
    })
    .catch((err) => {});
}

export async function updateTrainingStatus(
  candidateId: string,
  body: { status: OnboardingLessonStatus },
) {
  const url = `${baseURL}/proxy/onboarding/training/candidates/${candidateId}/status`;
  const headers = await getCustomHeaderAsync();
  if (!headers.Authorization) {
    return;
  }
  return http
    .patch(url, body, {
      headers,
      params: {
        lang: mapLanguage(getI18n().language),
      },
    })
    .then((res: any) => {})
    .catch((err) => {});
}

export async function uploadExamProof(filename: string, mimeType: string, module = "examProof") {
  let data = {
    mimeType: mimeType,
    module,
    accessLevel: "private",
    originalFilename: filename,
  };
  const response = await http.post(`${CORE_URL}/resource`, data, {
    headers: getCustomHeader(),
  });
  return response;
}

export async function uploadBlobFile(url: string, base64: string): Promise<any> {
  const requestOptions: RequestInit = {
    method: "PUT",
    headers: {
      "x-ms-blob-type": "BlockBlob",
      "Content-Type": "application/octet-stream",
    },
  };
  try {
    if (base64) {
      requestOptions.body = Buffer.from(base64, "base64");
    }
  } catch (err) {
    console.error(err);
    throw {
      kind: "Invalid  data",
    };
  }
  return fetch(url, requestOptions)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error("DEBUG --- Upload ", err);
      throw {
        kind: "bad-data",
      };
    });
}

export async function uploadResourceId(id: string) {
  let data = {
    uploaded: true,
  };
  return http
    .patch(`${CORE_URL}/resource/${id}/meta`, data, {
      headers: getCustomHeader(),
    })
    .then(function (response: any) {
      return response.data;
    })
    .catch(function (err: any) {
      throw {
        kind: "bad-data",
      };
    });
}
export async function deleteResourceId(id: string) {
  let data = {
    deleted: true,
  };
  return http
    .patch(`${CORE_URL}/resource/${id}/meta`, data, {
      headers: getCustomHeader(),
    })
    .then(function (response: any) {
      return response.data;
    })
    .catch(function (err: any) {
      throw {
        kind: "bad-data",
      };
    });
}

export async function getResourceById(id: string) {
  return http
    .get(`${CORE_URL}/resource?resourceIds=${[id]}`, {
      headers: getCustomHeader(),
    })
    .then(function (response: any) {
      return response.data;
    })
    .catch(function (err: any) {
      throw {
        kind: "bad-data",
      };
    });
}

export const createCandidate = async (data: {
  username: string;
  password: string;
  context: OtpContextEnum;
  otp: string;
}): Promise<any> => {
  return http.post(
    `${CORE_URL}/auth/initAccount`,
    { ...data, userType: UserTypeEnum.CANDIDATE },
    {
      headers: getCustomHeader(),
    },
  );
};
