import React from "react";
import styles from "./protocol-component.module.css";
import closeLogo from "../../../../../components/icon/assets/close.svg";
import { HeaderComponent } from "../../../../../components/header/header.component";

interface ProtocolComponentProps {
  title: string;
  desc: string;
  onClose: () => void;
}

export const ProtocolComponent: React.FC<ProtocolComponentProps> = ({ title, desc, onClose }) => {
  return (
    <div className={styles.fullscreen}>
      <HeaderComponent
        title={title}
        onBack={onClose}
        rightAnchor={<img src={closeLogo} alt="" onClick={onClose} />}
      />
      <div className={styles.content}>{desc}</div>
    </div>
  );
};
