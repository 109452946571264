import React from "react";

import { ProtocolComponent } from "./components/protocol-component";
import { useTranslation } from "react-i18next";
import { useProtocolScreen } from "./protocol-screen.hook";
export const PrivacyNoticeScreen = (props?: { onClose?: any }) => {
  const { handleClose } = useProtocolScreen({ id: 1 });
  const { t } = useTranslation();
  const title = t("Common.privacy_notice");
  const desc = t("Settings.privacy_policy_content");

  return <ProtocolComponent title={title} desc={desc} onClose={props?.onClose ?? handleClose} />;
};
