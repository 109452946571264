import { Security } from "@mui/icons-material";
import { mergeWith } from "lodash";
import { getI18n } from "react-i18next";

export const getViewPort = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

export const getBreakpoint = (mode: "sm" | "md" | "lg" | "xl") => {
  const breakpoints = {
    sm: 544, // Small screen / phone
    md: 768, // Medium screen / tablet
    lg: 992, // Large screen / desktop
    xl: 1200, // Extra large screen / wide desktop
  };
  return breakpoints[mode];
};

export function getIdTypeOptions(idTypeConfig: any) {
  if (!idTypeConfig) {
    return { options: [], default: "" };
  }
  let defaultKey = "";
  const options = Object.keys(idTypeConfig).map((key, index) => {
    const option = idTypeConfig[key];
    if (option.isDefault) {
      defaultKey = option.value;
    }
    return {
      text: getI18n().t(`Common.${key}`),
      value: option.value,
    };
  });
  return { options, default: defaultKey };
}

export function readConfigs(rawConfigs: any[] = []) {
  let appConfigs: any = {
    Security: require("./security/security-authorization-public-web.config.json"),
  };

  rawConfigs.forEach((item) => {
    const groupSuffix = item.group.split("pruforce.")[1];
    const content = item.content;

    if (!appConfigs[groupSuffix]) {
      appConfigs[groupSuffix] = {};
    }

    Object.keys(content).forEach((key) => {
      if (key === groupSuffix) {
        appConfigs[groupSuffix] = {
          ...appConfigs[groupSuffix],
          ...content[key],
        };
      } else {
        appConfigs[groupSuffix][key] = content[key];
      }
    });
  });

  appConfigs = mergeWith(appConfigs, appConfigs.Recruitment);
  return appConfigs;
}

export function formatRegionDisplay(regionCode: string, regionName: any) {
  // make sure the region code is always 5 characters long, with padding spaces
  const regionCodeLength = 6;
  const regionCodeStr = regionCode.toString();
  const regionCodeStrLength = regionCodeStr.length;
  const regionCodeStrPadding =
    regionCodeStrLength < regionCodeLength
      ? regionCodeStr.padEnd(regionCodeLength, " ")
      : regionCodeStr;
  const regionDisplayStr = `${regionCodeStrPadding} ${regionName}`;
  return regionDisplayStr;
}

export function isMobile() {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
}
