import { AuthenticationState } from "./auth-slice";

export const selectError = (state: { auth: AuthenticationState }) => {
  return state.auth.error;
};

export const selectToken = (state: { auth: AuthenticationState }) => {
  return state.auth.token;
};

export const selectIsLoading = (state: { auth: AuthenticationState }) => {
  return state.auth.isLoading;
};

export const selectCandidateProfile = (state: { auth: AuthenticationState }) => {
  return state.auth.candidateProfile;
};

export const selectAutoLogoutMinutes = (state: { auth: AuthenticationState }) => {
  return state.auth.autoLogoutMinutes;
};
